import styled from "styled-components"

export const Hero = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 50vw;
  height: 100vh;
  //margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Text styles */
  text-align: center;
  color: black;

  @media (max-width: 768px) {
    height: 50vh;
    width: 100vw;
  }
`

export const HeroImage = styled.img`
  max-width: 100%;
  height: auto;
  padding-left:24px;
  padding-right: 24px;

  @media (min-width: 768px) {
    //padding-top:50px;
 
    padding-left: 64px;
  }
`

export const HeroDescription = styled.div`
  width: 50vw;
  height: 100vh;
  font-size: 8vw;
  font-weight:600;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  justify-content: left;
  align-items: center;
  text-align: center;
  display: flex;
  margin-left: 24px;
  //padding-top:25px;
  color: #ffffff;

  @media (max-width: 768px) {
    display: none;
  }
`

export const HeroDivider = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 8px;
  height: 300px;
  background-color: #ee7623;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`

export const HeroWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 80vh;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  //background-color:#2c333e;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(44, 51, 62, 1) 73%,
    rgba(44, 51, 62, 1) 100%
  );
  @media (min-width: 768px) {
    flex-flow: row;
  }
  @media (max-width: 768px) {
    border: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top:0;
  }
  flex: 1;
  z-index: 0;
`

export const IndexWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`

export const HomeImageText = styled.div`
  font-size: 36px;
  font-weight: 600;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  display: flex;
  justify-content: left;
  align-items: left;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 25vw;

  @media (min-width: 768px) {
    justify-content: left;
    //width: 270px;
  }
`

export const IndexSection = styled.div`
  background-color: ${props => props.colour};
  display: flex;
  justify-content: center;
  align-items: left;
  flex-grow: 1;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;

  margin-left: 24px;
  margin-right: 24px;
  flex: 1;
  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: left;
  }
`
export const IndexSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 12px;
  margin-top: 12px;
  //border-bottom: 8px solid ${props => props.colour};
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: left;
    //border-left: 8px solid ${props => props.colour};
    //border-right: 8px solid ${props => props.colour};
  }
  @media (max-width: 768px) {
    //border-top: 8px solid ${props => props.colour};
    //border-bottom: 8px solid ${props => props.colour};
  }
`

export const IndexSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  min-width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: left;
    max-width: 25%;
    min-width: 25%;
  }

  border-bottom: 8px solid ${props => props.colour};
`

export const IndexSectionHeaderTitle = styled.div`
font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 24px;
  padding-left: 12px;
`

export const IndexSectionItems = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.colour};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  flex: 1;
  border-bottom: 8px solid ${props => props.borderColour};
`
export const IndexSectionCircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 12px;
  margin-top: 12px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: left;
  }
`

export const IndexSectionCircleHeader = styled.div`
  display: flex;
  flex-direction: column;
  //margin-right: 24px;
  box-shadow: 3px 3px 5px 2px #ccc;
  //max-width: 100%;
  //min-width: 100%;
  width:250px;
  height:250px;
  border-radius: 50%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: left;
    //max-width: 25%;
    //min-width: 25%;
  }

  //border: 1px solid ${props => props.colour};
`
