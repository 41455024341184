import styled from "styled-components"

export const ServiceWrapper = styled.div`
  background-image: linear-gradient(${props => props.fillColorStart}, ${props =>
  props.fillColorEnd} );
  color: #ffffff;
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom:24px;
  padding-bottom:24px;
  padding-right:24px;
  box-shadow: 3px 3px 5px 2px #ccc;
  flex-grow: 0;
  flex-basis:33%;
  border-bottom: 5px solid ${props => props.bottomBorder};
  @media (max-width: 768px) {
    flex-direction: column;
    
  }
`

export const ServiceDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
`

export const ServiceDescriptionHeader = styled.h2`
  &::before{
  content:"${props => props.descriptionHeader}";
  }
`

export const ServiceDescription = styled.div`
    font-size: 22px;
  // font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  //   Helvetica, Arial, "Lucida Grande", sans-serif;
`

export const ServiceLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
