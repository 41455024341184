import styled from "styled-components"

export const ClientsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: transparent;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: left;
    padding: 0;
  }
`
export const ClientsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 0px;
  background-color: #ebebeb;
  filter: grayscale(100%);
  margin-top: 24px;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    justify-content: center;
    padding: 0;
    flex-direction:row;
  }
`

