import React, { Component } from "react"
import {
  ServiceWrapper,
  ServiceDescriptionWrapper,
  ServiceDescriptionHeader,
  ServiceDescription
} from "./service.styles"

class Service extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <ServiceWrapper fillColorStart={this.props.fillColorStart} fillColorEnd={this.props.fillColorEnd} bottomBorder={this.props.bottomBorder}>
          <ServiceDescriptionWrapper>
            <ServiceDescriptionHeader
              descriptionHeader={this.props.descriptionHeader}
            ></ServiceDescriptionHeader>
            <ServiceDescription>{this.props.description}</ServiceDescription>
          </ServiceDescriptionWrapper>
        </ServiceWrapper>
      </React.Fragment>
    )
  }
}

export default Service
