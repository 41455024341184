import React from "react"
import { ServicesWrapper } from "./services.styles"
import Service from "./service.component"

const ApplicationDevelopment = props => (
  <React.Fragment>
    Delivery of custom application development, including requirements analysis
    and delivered through a wide range of technologies.
  </React.Fragment>
)

const AzureDevelopment = props => (
  <React.Fragment>
    Utilising Azure to deliver a range of services from integration to cognitive. Building Azure DevOps solutions for continuous integration and continuous delivery.
  </React.Fragment>
)

const MicrosoftBusinessApplications = props => (
    <React.Fragment>
      Delivering software built on top of the Microsoft Business Applications platform, including Dynamics 365 Customer Engagement and PowerApps.
    </React.Fragment>
  )

const Services = () => (
  <React.Fragment>
    <h1>Development Services</h1>
    <ServicesWrapper>
      <Service
        fillColorStart="#AF231C"
        fillColorEnd="#DC3513"
        bottomBorder="red"
        descriptionHeader="Custom Application Development"
        description={<ApplicationDevelopment />}
      />
      <Service
        fillColorStart="#F2A900"
        fillColorEnd="#F6B700"
        bottomBorder="orange"
        descriptionHeader="Azure Development &amp; Azure DevOps"
        description={<AzureDevelopment />}
      />
      <Service
        fillColorStart="#509E2F"
        fillColorEnd="#43B02A"
        bottomBorder="green"
        descriptionHeader="Microsoft Business Applications"
        description={<MicrosoftBusinessApplications />}
      />
    </ServicesWrapper>
  </React.Fragment>
)

export default Services
