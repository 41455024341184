import styled from "styled-components"

export const ClientLogo = styled.div`
  background-image: url(${props => props.logoUrl});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
  width: 150px;
  margin: 20px;
`
export const ClientImage = styled.img`
  max-width: 100%;
  height: auto;
`
