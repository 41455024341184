import React from "react"
import Layout from "../components/layout/layout.component"
import { GlobalStyle } from "../styles/global.style"
import HeroUrl from "../images/bw-hero-new.svg"
import {
  HeroImage,
  HeroDescription,
  HeroWrapper,
  HeroDivider,
} from "../styles/index.styles"
import Clients from "../components/client/clients.component"
import Services from "../components/service/services.component"
import { Helmet } from "react-helmet"

export default () => (
  <div>
    <GlobalStyle />
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>BayesWorks Ltd</title>
        <link rel="canonical" href="https://bayesworks.io/"></link>
        <meta property="og:title" content="bayesworks ltd"/>
        <meta property="og:type" content="website"/>
        <meta property="og:description" content="Azure and Microsoft Business Apps Consultancy."/>
        <meta property="og:image" content="https://bayesworks.io/static/bw-og-image.png"/>
        <meta property="og:url" content="https://bayesworks.io"/>     
      </Helmet>
    </div>
    <Layout>
      <HeroWrapper>
        <HeroImage src={HeroUrl}></HeroImage>
        <HeroDivider></HeroDivider>
        <HeroDescription>
          Software...
          <br />
          {"..."}
          Delivered
        </HeroDescription>
      </HeroWrapper>
      <Services />

      <Clients />
    </Layout>
  </div>
)
