import React from "react"
import TransUnion from "../../images/clients/transunion.svg"
import Ergo from "../../images/clients/ergo.png"
import Capgemini from "../../images/clients/capgemini.svg"
import Shu from "../../images/clients/shu.svg"
import Hcl from "../../images/clients/hcl.png"
import Capita from "../../images/clients/capita.png"
import Synchronicity from "../../images/clients/synchronicity.png"
import Client from "./client.component"
import { ClientsWrapper } from "./clients.styles"

const Clients = props => (
  <React.Fragment>
    <h1>Clients</h1>
    <ClientsWrapper>
      <a
        href="https://www.capgemini.com/gb-en/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={Capgemini} />
      </a>
      <a
        href="https://www.capita.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={Capita} />
      </a>
      <a
        href="https://www.ergogroup.ie/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={Ergo} />
      </a>
      <a
        href="https://www.hcltech.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={Hcl} />
      </a>
      <a
        href="https://www.shu.ac.uk/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={Shu} />
      </a>

      <a
        href=" https://www.synchronicity-systems.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={Synchronicity} />
      </a>

      <a
        href="https://www.transunion.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Client logoUrl={TransUnion} />
      </a>
    </ClientsWrapper>
  </React.Fragment>
)

export default Clients
