import styled from "styled-components"

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ServicesHeader = styled.div`
  padding-left: 24px;
  padding-right:24px;
  font-size: 64px;
  font-weight: 600;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  @media (max-width: 768px) {
    font-size:48px;
  }
`
